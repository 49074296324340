<template>
  <div class="view-login">
    <div class="container">
      <main class="row justify-content-center py-5">
        <form
          @submit.prevent="submit"
          class="col-md-5 col-lg-4 align-self-center text-center"
        >
          <img class="logo" src="@/assets/images/logo.png" />

          <h5 class="mt-5">Faça seu login</h5>
          <div class="mb-4">Informe seu e-mail e senha</div>

          <form-group
            id="email"
            placeholder="Digite o usuário"
            v-model="form.email"
            :errors="errors.email"
          />
          <form-group
            id="password"
            type="password"
            placeholder="Digite sua senha"
            v-model="form.password"
            :errors="errors.password"
          />

          <div class="text-end">
            <a
              href="javascript:void"
              @click="forgotDialog = true"
              class="btn-forgot text-light"
            >
              ESQUECEU A SENHA?
            </a>
          </div>

          <button class="btn btn-primary my-4 w-100 btn-lg" :disabled="loading">
            <loading :show="loading">Entrar</loading>
          </button>
        </form>
      </main>
    </div>
  </div>

  <el-dialog v-model="forgotDialog" title="Esqueceu a senha">
    <forgot-password @close="forgotDialog = false" />
  </el-dialog>
</template>

<script>
import { formFields } from "@/functions";
import ForgotPassword from "@/components/login/ForgotPassword";

export default {
  components: { ForgotPassword },
  data() {
    return {
      ...formFields(["email", "password"]),
      forgotDialog: false,
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.loading = true;

      this.$store
        .dispatch("accounts/signin", this.form)
        .then(() => {
          this.$store.dispatch("accounts/get").finally(() => {
            if (this.$route.query.path) {
              this.$router.push(this.$route.query.path);
            } else {
              this.$router.push({ name: "index" });
            }
            this.loading = false;
          });
        })
        .catch((error) => {
          this.errors = error.response.data;
          if (error.response.data.message) {
            this.$message({
              showClose: true,
              message: error.response.data.message[0],
              type: "error",
              duration: 10000,
            });
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-login {
  position: relative;
  background-image: url("../assets/images/login-bg.jpg");
  background-size: cover;
  background-position: center center;

  .bg {
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    max-height: 100vh;
    max-width: 100%;
  }

  main {
    min-height: 100vh;

    .btn-forgot {
      min-height: 0;
      padding: 0;
      font-size: 12px;
      text-decoration: none;
    }

    .logo {
      height: 100px;
    }
  }
}
</style>
