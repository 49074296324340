<template>
  <div class="login-forgot-password text-center">
    <h4 class="px-4">Digite seu e-mail de cadastro</h4>

    <div class="px-4 pb-4">
      Você receberá, em seu e-mail, o link para redefenir a sua senha
    </div>

    <form @submit.prevent="submit">
      <form-group
        v-model="form.email"
        id="email"
        type="email"
        placeholder="Digite a seu e-mail"
        :errors="errors.email"
      />

      <div class="row">
        <div class="col-6 pe-5">
          <button
            class="btn w-100 btn-outline-secondary"
            type="button"
            @click="close"
          >
            Fechar
          </button>
        </div>

        <div class="col-6 ps-5">
          <button
            class="btn w-100 btn-primary"
            type="submit"
            :disabled="loading"
          >
            <loading :show="loading">Enviar</loading>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { formFields } from "@/functions";

export default {
  props: {
    visible: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      ...formFields(["email"]),
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.loading = true;

      this.$store
        .dispatch("accounts/forgot", this.form)
        .then(() => {
          this.$message({
            showClose: true,
            message: "Enviado com sucesso, confira o seu email",
            type: "success",
            duration: 10000,
          });
          this.close();
        })
        .catch((error) => {
          let message;
          if (error.response) {
            this.errors = error.response.data;
            if (error.response.data.message) {
              message = error.response.data.message[0];
            }
          } else {
            message = "Erro! Por favor, tente novamente";
          }

          if (message) {
            this.$message({
              showClose: true,
              message,
              type: "error",
              duration: 10000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
